import * as moment from 'moment-timezone';

export default {
  fetching: false,
  reservations: [],
  inactiveReservations: [],
  selectedReservationId: null,
  creatingReservation: false,
  calendarDate: moment().startOf('month').toDate(),
  isAdmin: false,
  currentProducerId: null,
  startDate: null,
  endDate: null,
  pivotDate: null,
  draggingReservation: false,
  mouseDown: false,
  mouseOnDate: null,
  artists: {},
  producers: [],
  errors: [],
  showModal: false,
  showFormModal: false,
  modalData: {
    header: '',
    body: '',
    button: '',
    footer: '',
    action: {
      name: '',
      exec: null,
    },
  },
  confirmationAttempts: [],
  duels: [],
  currentUser: null,
  duelsModalShown: false,
  holidays: [],
  rescheduledReservations: [],
  transferAttempts: [],
  arenaTypes: [],
  currentRate: null,
};

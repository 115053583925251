<template>
  <div class="reservation-actions">
    <div class="sidebar__actions">
      <button
        v-if="showConfirmButton"
        @click="confirmStartConfirmationJourney()"
        class="sidebar__action"
      >
        Quiero confirmar
      </button>
      <button
        v-if="showRemoveButton"
        @click="confirmRemoveReservation()"
        class="sidebar__action"
      >
        Eliminar
      </button>
      <button
        v-if="showRenewButton"
        @click="confirmRenewReservation()"
        class="sidebar__action"
      >
        Renovar
      </button>
      <button
        v-if="showMarkAsZombieButton"
        @click="openMarkAsZombieConfirmation()"
        class="sidebar__action sidebar__action--highlight"
      >
        No puedo confirmar ahora
      </button>
      <button
        v-if="showRescheduleButton"
        @click="$emit('reschedule-form')"
        class="sidebar__action"
      >
        Quiero reagendar
      </button>
      <button
        v-if="showReassignButton"
        @click="$emit('reassign-form')"
        class="sidebar__action"
      >
        Quiero confirmar y reasignar
      </button>
      <button
        v-if="showTransferButton"
        @click="$emit('transfer-form')"
        class="sidebar__action"
      >
        Quiero ceder la fecha
      </button>
      <button
        v-if="showCancelButton"
        @click="$emit('cancel-form')"
        class="sidebar__action"
      >
        Quiero cancelar la fecha
      </button>
    </div>
  </div>
</template>
<script>

import * as actions from '../store/action-types';

const AVAILABLE_ACTIONS = ['confirm', 'remove', 'renew', 'mark_as_zombie', 'reschedule', 'transfer', 'cancel'];

export default {
  name: 'ReservationActions',
  props: {
    reservation: {
      type: Object,
      default: () => null,
    },
    only: {
      type: Array,
      default: () => AVAILABLE_ACTIONS,
    },
    rate: {
      type: String,
      default: '',
    },
  },
  computed: {
    attemptingToConfirm() {
      return !!this.confirmationAttempt;
    },
    confirmationAttempt() {
      return this.$store.getters.confirmationAttempt(this.reservation);
    },
    showConfirmButton() {
      return this.reservation.canStartConfirmationJourney && this.only.includes('confirm');
    },
    showReassignButton() {
      return this.showConfirmButton && this.reservation.canReassign;
    },
    showMarkAsZombieButton() {
      return this.canMarkAsZombie && this.only.includes('mark_as_zombie');
    },
    canMarkAsZombie() {
      return this.reservation.canBeTransformedInZombie;
    },
    canConfirm() {
      return this.reservation.canConfirm;
    },
    showRemoveButton() {
      return this.canRemove && this.only.includes('remove');
    },
    canRemove() {
      return !!this.reservation.canRemove;
    },
    showRenewButton() {
      return this.canRenew && this.only.includes('renew');
    },
    canRenew() {
      return this.reservation.canRenew;
    },
    showRescheduleButton() {
      return this.canReschedule && this.only.includes('reschedule');
    },
    canReschedule() {
      return this.reservation.canReschedule;
    },
    showTransferButton() {
      return this.canTransfer && this.only.includes('transfer');
    },
    canTransfer() {
      return this.reservation.canTransfer;
    },
    showCancelButton() {
      return this.canCancel && this.only.includes('cancel');
    },
    canCancel() {
      return this.reservation.canCancel;
    },
  },
  methods: {
    renewReservation() {
      this.$store.dispatch(actions.RENEW_RESERVATION, this.reservation);
      this.$store.dispatch(actions.CLOSE_MODAL);
    },
    confirmRenewReservation() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        this.confirmRenewReservationDataInfo(),
      );
    },
    confirmRenewReservationDataInfo() {
      return {
        header: 'Estás a punto de renovar esta reserva',
        body: '¿Estás seguro de realizar esta acción?',
        button: 'Volver',
        action: {
          name: 'Sí, renovar.',
          exec: this.renewReservation,
        },
      };
    },
    startConfirmationJourney() {
      if (this.reservation.canStartConfirmationJourney) {
        this.$store.dispatch(
          actions.START_CONFIRMATION_JOURNEY,
          {
            reservation: this.reservation,
            options: {
              modal: this.reservation.firstPriority ?
                'confirmation_process_modal' : 'confirmation_journey_modal',
            },
          },
        );
        this.$store.dispatch(actions.CLOSE_MODAL);
      } else {
        this.$store.dispatch(
          actions.OPEN_MODAL,
          this.mustCreditModalDataInfo('confirmar'),
        );
      }
    },
    confirmStartConfirmationJourney() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        this.confirmStartConfirmationJourneyDataInfo(),
      );
    },
    confirmStartConfirmationJourneyDataInfo() {
      return {
        header: 'Estás a punto de confirmar esta reserva',
        body: `¿Estás seguro de realizar esta acción? ${this.rate ? `Valor estimado:${this.rate}` : ''}`,
        button: 'Volver',
        action: {
          name: 'Sí, confirmar.',
          exec: this.startConfirmationJourney,
        },
      };
    },
    openMarkAsZombieConfirmation() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        {
          header: 'No puedo confirmar ahora',
          body: 'Esta acción no eliminará tu reserva. En caso de que la reserva ' +
            'que te desafía no logre confirmar, tu reserva volverá a su posición original.',
          button: 'Volver',
          action: {
            name: 'No puedo confirmar ahora',
            exec: this.markAsZombie,
          },
        },
      );
    },
    markAsZombie() {
      this.$store.dispatch(actions.MARK_RESERVATION_AS_ZOMBIE, this.reservation);
      this.$store.dispatch(actions.CLOSE_MODAL);
    },
    confirmRemoveReservation() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        this.confirmDestroyModalDataInfo(),
      );
    },
    removeReservation() {
      this.$store.dispatch(actions.REMOVE_RESERVATION, this.reservation);
      this.$store.dispatch(actions.CLOSE_MODAL);
    },
    confirmDestroyModalDataInfo() {
      return {
        header: 'Estás a punto de eliminar esta reserva',
        body: 'Esta acción es irreversible.',
        button: 'Volver',
        action: {
          name: 'Eliminar',
          exec: this.removeReservation,
        },
      };
    },
    mustCreditModalDataInfo(reason) {
      return {
        header: `No puedes ${reason} aún`,
        body: `Para ${reason} debes realizar un abono de $5.000.000 a la cuenta:\n` +
          'Una vez validado el abono, te enviaremos un correo.',
        button: 'Entendido',
      };
    },
  },
};
</script>

<template>
  <div
    class="reservation-detail"
  >
    <div
      class="reservation-detail__container sidebar"
    >
      <div
        class="reservation-detail__close sidebar__close"
        @click="close()"
      >
        <div class="close-icon" />
      </div>
      <h4
        class="sidebar__subtitle-margin"
      >
        Reservas inactivas para el {{ inactivesDate }}
      </h4>
      <div
        v-for="reservation in inactiveReservations"
        :key="reservation.id"
      >
        <div
          class="sidebar__element"
        >
          <h1
            class="reservation-detail__title sidebar__title"
            v-if="reservation.eventName"
          >
            {{ reservation.eventName }}
          </h1>
          <h2 class="reservation-detail__subtitle sidebar__subtitle">
            {{ reservation.producerName }}
          </h2>
          <div class="reservation-detail__body sidebar__body">
            <div
              class="reservation-detail__info sidebar__info"
              v-if="reservation.id"
            >
              <div class="reservation-detail__label sidebar__label">
                <div class="barcode-icon" />
                Código de reserva:
              </div>
              <div class="reservation-detail__label sidebar__label">
                {{ reservation.id }}
              </div>
            </div>
            <div class="reservation-detail__info sidebar__info">
              <div class="reservation-detail__label sidebar__label">
                <div class="state-icon" />
                Estado:
              </div>
              <div
                class="reservation-detail__label sidebar__label reservation-state__cannot-confirm"
              >
                {{ RESERVATION_STATES[reservation.state] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as moment from 'moment';
import * as actions from '../store/action-types';

export default {
  name: 'InactiveReservations',
  data() {
    return {
      RESERVATION_STATES: {
        'discarded': 'Descartada',
        'removed': 'Removida',
        'zombie': 'Zombie',
        'expired': 'Expirada',
        'rescheduled': 'Reagendada',
      },
    };
  },
  computed: {
    inactiveReservations() {
      const reservations = this.$store.getters.inactiveReservationsGroupedByDay;
      const day = this.$store.state.calendar.startDate;

      return reservations[day];
    },
    inactivesDate() {
      const date = new Date(this.$store.state.calendar.calendarDate);
      const day = this.$store.state.calendar.startDate;

      return moment(date).add(day - 1, 'days').format('DD/MM/YYYY');
    },
  },
  methods: {
    close() {
      this.$store.dispatch(actions.CLOSE_INACTIVE_RESERVATIONS);
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reservation-tag"},[_c('div',{staticClass:"calendar-cell-reservation__item",class:{
      'reservation-tag--empty': _vm.isEmpty,
      'reservation-tag--is-first-for-date': _vm.isFirstForDate,
      'reservation-tag--confirmed': _vm.isConfirmed,
      'reservation-tag--not-first-for-date': _vm.notFirstForDate,
      'reservation-tag--mounting': _vm.isMountingDay,
      'reservation-tag--dismounting': _vm.isDismountingDay,
      'reservation-tag--highlight': _vm.shouldHighlight,
      'reservation-tag--private': !_vm.canSeeArtistOrFestival,
      'reservation-tag--reservation-start': _vm.isFirstReservationDay && !_vm.isLastReservationDay,
      'reservation-tag--reservation-end': _vm.isLastReservationDay && !_vm.isFirstReservationDay,
      'reservation-tag--reservation-middle': !_vm.isLastReservationDay && !_vm.isFirstReservationDay,
      'reservation-tag--reservation-single': _vm.isLastReservationDay && _vm.isFirstReservationDay,
    }},[(_vm.reservation)?_c('div',{staticClass:"reservation-tag__content"},[(_vm.canSeePrivateData)?_c('span',{staticClass:"reservation-tag__private-info"},[_c('span',{staticClass:"reservation_tag__data"},[_vm._v("\n          "+_vm._s(_vm.tagText)+"\n        ")])]):_vm._e(),_vm._v(" "),(!_vm.canSeePrivateData && _vm.canSeeArtistOrFestival)?_c('span',{staticClass:"reservation-tag__private-info"},[_c('span',{staticClass:"reservation_tag__data"},[_vm._v("\n          "+_vm._s(_vm.eventText)+"\n        ")])]):_vm._e(),_vm._v(" "),(_vm.attemptingToConfirm)?_c('span',{staticClass:"reservation-tag__private-info"},[_vm._m(0)]):_vm._e(),_vm._v(" "),(_vm.showConfirmationJourneyStar)?_c('span',{staticClass:"reservation-tag__private-info"},[_vm._m(1)]):_vm._e(),_vm._v(" "),(_vm.isZombie)?_c('span',{staticClass:"reservation-tag__private-info"},[_vm._m(2)]):_vm._e(),_vm._v(" "),(_vm.reservation.duels.length > 0 && !_vm.showConfirmationJourneyStar)?_c('span',{staticClass:"reservation-tag__duel-icon"},[_c('img',{staticClass:"icon-image",attrs:{"src":require("../../assets/images/swords.png")}})]):_vm._e()]):_c('div',{staticClass:"reservation-tag__content"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"reservation_tag__data"},[_c('img',{staticClass:"icon-image",attrs:{"src":require("../../assets/images/star.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"reservation_tag__data"},[_c('img',{staticClass:"icon-image",attrs:{"src":require("../../assets/images/star-white.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"reservation_tag__data"},[_c('img',{staticClass:"icon-image",attrs:{"src":require("../../assets/images/zombie.png")}})])}]

export { render, staticRenderFns }